import React from 'react';
import {Helmet} from "react-helmet";
import { ProfileHero, ProfileBody, ProfileSlider } from '../../components';
import Style from './StudentProfile.css';

const StudentProfile = props => {
	const student = props.studentData;
	return (
		<div style={Style.StudentProfile} className="student_profile sticky">
			<Helmet>
				<title>{student.firstName} | We are Wildcats | The University of Arizona</title>
				<meta name="description" content={student.studentMetaDescription} />
			</Helmet>
			<ProfileHero studentData={student} reverseIcons={props.reverseIcons} />
			<ProfileBody vid={student.videoLink} studentName={student.firstName} reverseIcons={props.reverseIcons} questions={student.suppQA} iconRev={props.iconRev} />
			<ProfileSlider currStudent={student.id} randomProfiles={props.randomProfiles} />
		</div>
	);
};

export default StudentProfile;
