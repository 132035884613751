import React from 'react';
import Style from './HomeContent.css';

const HomeContent = props => {
	return (
		<div style={Style.HomeContent} className="home_content_section">
			<div className="home_descritption container">
				<p>Today’s University of Arizona students and recent grads are advocates, creators, hackers, role models, influencers and, of course, scholars. They’re determined and energetic; they’re outspoken and innovators.</p>
				<br />
				<br />
				<p>In a time of dramatic change, Wildcats prepare for whatever comes next – for them, and for the world. Get to know standout University of Arizona students who lead positive change, create original paths and embrace the possibilities in the years ahead.</p>
			</div>
			<div className="home_button_wrap">
				<p className="cta_lead">
					What will you become at the University of Arizona? <span>Apply or commit, and be an Arizona Wildcat.</span>
				</p>
				<a id="apply-now-home" href="https://slate.admissions.arizona.edu/apply/">
					APPLY NOW
				</a>
				<a id="commit-now-home" href="https://nextsteps.arizona.edu/nextsteps/api/trackingLink?CampaignId=fresh-we-are-wildcats-nscl-cta">
					COMMIT NOW
				</a>
			</div>
		</div>
	);
};

export default HomeContent;
