import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header, Menu, StudentProfile, Home } from './components';
import './App.css';

const App = props => {
	const [menuState, setMenuState] = useState(''),
		[iconRev, setIconRev] = useState('white'),
		[lastColor, setLastColor] = useState('white');

	const toggleMenuState = () => {
		menuState === 'closed' || menuState === '' ? setMenuState('open') : setMenuState('closed');
	};

	const reverseIcons = color => {
		setLastColor(iconRev);
		setIconRev(color);
	};
	return (
		<div className="App">
			<div className="main_container">
				<Header menuState={menuState} iconRev={iconRev} toggleMenuState={toggleMenuState} reverseIcons={reverseIcons} />
				<Menu menuState={menuState} iconRev={iconRev} lastColor={lastColor} toggleMenuState={toggleMenuState} reverseIcons={reverseIcons} studentData={props.orderedProfiles} />
				<Switch>
					{props.orderedProfiles.map(profile => (
						<Route key={profile.id} path={profile.proPath} render={() => <StudentProfile studentData={profile} randomProfiles={props.randomProfiles} iconRev={iconRev} reverseIcons={reverseIcons} />} />
					))}
					<Route path="/">
						<Home className="intrSect" allStudents={props.randomProfiles} iconRev={iconRev} reverseIcons={reverseIcons} />
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default App;
