import React from 'react';
import Style from './Header.css';
import logoBlue from '../../img/arizona-logo-blue.svg';
import logoWhite from '../../img/arizona-logo-white.svg';
import homeBlue from '../../img/home-button-blue.svg';
import homeWhite from '../../img/home-button-white.svg';

const Header = props => {
	return (
		<div style={Style.Header} className={`header ${props.iconRev === 'blue' ? 'bg_white' : 'bg_blue'}`}>
			<a href="https://arizona.edu" className="az_site" target="_blank" rel="noopener noreferrer">
				<img src={props.iconRev === 'blue' ? logoBlue : logoWhite} alt="Arizona Logo" />
			</a>
			<div className="head_bttns">
				<a href="/" className="home_bttn">
					<img src={props.iconRev === 'blue' ? homeBlue : homeWhite} alt="Home Icon" />{' '}
				</a>
				<button id="menu_bttn" aria-haspopup="true" aria-controls="main_menu" aria-label="Open the menu" className={`menu_bttn ${props.menuState} ${props.iconRev}`} onClick={props.toggleMenuState}>
					<span></span>
					<span></span>
				</button>
			</div>
		</div>
	);
};

export default Header;
