import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Style from './ProfileSlider.css';

const ProfileSlider = props => {
	const settings = {
		className: 'student_slider',
		dots: false,
		infinite: true,
		centerMode: true,
		centerPadding: '30px',
		speed: 800,
		initialSlide: 0,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	return (
		<section style={Style.ProfileSlider} className="profile_slider">
			<div className="wrapper">
				<div className="slider_container">
					<Slider {...settings}>
						{props.randomProfiles.map(student =>
							props.currStudent !== student.id ? (
								<div key={student.id}>
									<Link to={student.proPath} id={`slider-${student.firstName}`} className="student_slide">
										<img src={require(`../../img/slider/${student.imgFile}.png`)} alt={student.firstName} />
										<strong>{student.firstName.toUpperCase()}</strong>
										<span>{student.descrip.toUpperCase()}</span>
									</Link>
								</div>
							) : (
								''
							)
						)}
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default ProfileSlider;
