import React, { Fragment } from 'react';

const profiles = [
	{
		id: 0,
		firstName: 'Aja',
		descrip: 'The Storyteller',
		year: 'Class of 2021',
		major: 'Africana Studies and Pre-Retailing & Consumer Sciences',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Aja is an Africana Studies and Pre-Retailing and Consumer Sciences graduate from the University of Arizona. She is a storyteller and does everything with passion. Get to know her goals on her We Are Wildcats student profile.',
		studentSummary: 'No matter what Aja does – whether in her studies or in her hobbies – she does it with passion. Her goal? To create a more complete picture, personally and professionally.',
		pullQuote: "I’m inspired by stories. I'm really big on learning about people's journey and what exactly has led them to where they're at now, and where they think they'll be in the future.",
		videoLink: '675685442?h=297f69ab1a',
		imgFile: 'aja',
		proPath: '/profile/aja',
		suppQA: [
			{
				id: 0,
				question: 'You study two very different areas of interest – how do you connect them?',
				answer: (
					<Fragment>
						<p>When looking at companies and brands, I'm really passionate about looking at the story and learning about the individuals behind it. Who’s running the meetings and coming up with how to connect to consumers? I think it makes for a very strong company and consumer relationship when you mesh those two things together.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'Do you have a motto or a code that you try to live by?',
				answer: (
					<Fragment>
						<p>To thine own self, be true. I think it's essential to know where your morals stand, what you're passionate about, what you're capable of and what you enjoy. You'll be a lot more thankful for being honest with yourself and recognizing your own strengths and knowing who you are – and, also taking into account that that might change.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What’s most rewarding part about being a college student so far?',
				answer: (
					<Fragment>
						<p>One of the things I find most fulfilling as a student is the opportunity to be here. I never take the education that I'm getting for granted. The University of Arizona offers such a wide variety of courses, as well as many clubs and organizations, and so I think that there is that richness.</p>
						<p>Having so many different things accessible to me – I feel like I'm never left with a lack of information. And, getting to meet so many different people from so many different backgrounds and so many different interests. It enriches me to learn about others outside of the classroom.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 1,
		firstName: 'Caitlyn',
		descrip: 'The Luminary',
		year: 'Class of 2021',
		major: 'Nutritional Sciences; Biochemistry (minor)',
		homeCity: 'Mesa',
		homeState: 'AZ',
		studentMetaDescription: 'Caitlyn graduated with a degree in Nutritional Sciences and a minor in Biochemistry from the University of Arizona. With a future in medicine, get to know how she hopes to make an impact in our Tucson community on her We Are Wildcats student profile.',
		studentSummary: 'Caitlyn brings heart to her volunteer work – providing supplemental healthcare in Mexico, feeding the homeless in downtown Tucson and training guide dogs for the blind – on her path to a future in medicine.',
		pullQuote: "To be a college student in the year 2020 means that you get to find new ways to make big impacts in the world. The world is in your hands as a college student, and you have things you couldn't even imagine at your fingertips. Do with it what you will, and do big things.",
		videoLink: '675686477?h=d13ee05599',
		imgFile: 'caitlyn',
		proPath: '/profile/caitlyn',
		suppQA: [
			{
				id: 0,
				question: 'What has been the biggest surprise about life as a college student?',
				answer: (
					<Fragment>
						<p>Your life is in your own hands as a college student, because you get to decide what you do and when you do it, who you spend your time with. You get to pick everything for yourself. When you go to the gym, when you go to bed, when you study, what amount of time you spend with your friends – it's all up to you.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'Can you tell us about your volunteer work?',
				answer: (
					<Fragment>
						<p>I’m the co-president of Flying Samaritans, which runs a free medical clinic in Mexico once a month. I also volunteer at Z Mansion, where they serve breakfast and put on a clinic for the local homeless population. And, I spend time volunteering with the Guide Dogs for the Blind program.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: "Why do you feel that it's so important to help others?",
				answer: (
					<Fragment>
						<p>I decided to go into medicine because I wanted to have a hands-on role impacting the lives of others, making small and big differences. I had my own experiences as a patient and the physician made a really big difference in my life. I want to be able to do that for my patients one day.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'You spend so much of your time helping others. How do you take care of yourself?',
				answer: (
					<Fragment>
						<p>If I'm really stressed out, I like to go and run on the treadmill and lift some weights to get all the excess energy out so that I can take a deep breath and calm down afterward. The University of Arizona fosters an environment where self-care comes first – all students should look after themselves and what they need first. I value that highly.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 2,
		firstName: 'Emani',
		descrip: 'The Creative',
		year: 'Sophomore',
		major: 'Africana Studies',
		homeCity: 'Atlanta',
		homeState: 'GA',
		studentMetaDescription: 'Emani is a Sophomore studying Africana Studies at the University of Arizona. She is the creative, finding her own form of self-expression along her college journey. Get to know how she expresses her creativity on We Are Wildcats student profile.',
		studentSummary: 'In a world where noise can drown out your inner voice, Emani has found a way to turn life as a college student into its own form of self-expression.',
		pullQuote: 'Life as a creative being is feeling everything around you and finding a way to express that – getting rid of emotional debris and making something beautiful for others.',
		videoLink: '675687553?h=2a0f15a54e',
		imgFile: 'emani',
		proPath: '/profile/emani',
		suppQA: [
			{
				id: 0,
				question: 'How do you find inspiration?',
				answer: (
					<Fragment>
						<p>Being grateful for the things around me is constantly inspiring, and getting to experience life. I am a college student – that’s something to be inspired about.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'Any advice for a new college student?',
				answer: (
					<Fragment>
						<p>Always ask questions – there’s no such thing as a dumb question – use your resources, and find community.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'How do you express your creativity?',
				answer: (
					<Fragment>
						<p>I am a musician first and foremost, it is my biggest passion – I sing, I rap, I write all of my own music, I play a few instruments. I created Doin’ B.L.A.C.K., a podcast where we discuss the cultural implications of being a black woman, of being black in general, and have conversations with other ethnic and racial groups. I am also the founder and executive producer of my YouTube channel, The Chronicles of I, LOTUS. I also paint.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What are you hopeful for in 2020?',
				answer: (
					<Fragment>
						<p>I am looking for clarity. To be more specific, clarity within myself – who I am – and clarity in where I want to take myself, because there are many different things I could do with my life.</p>
					</Fragment>
				)
			},
			{
				id: 4,
				question: 'What advice would you give to future college students?',
				answer: (
					<Fragment>
						<p>Follow your heart, as well as your instinct. It’s a bit of a cliché, but if there is a feeling that pushes you toward a certain place, there is a reason for that feeling.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 3,
		firstName: 'Jada & Sabrina',
		descrip: 'The Entertainers',
		year: 'Juniors',
		major: 'Care, Health & Society',
		homeCity: 'Corona & Moreno Valley',
		homeState: 'CA',
		studentMetaDescription: 'Jada and Sabrina are on the University of Arizona soccer team – are best friends and working towards their Health & Society degrees. They are the entertainers, bringing energy into every room. Find out what their college experience is like on their We Are Wildcats profile.',
		studentSummary: 'Jada and Sabrina – Forward and Defender on the University of Arizona soccer team – are best friends, and they make you want to be their best friend, too.',
		pullQuote: "We bring a presence in the energy into the room. If you have an upbeat personality, it brings everybody up. If you're sad, come with us, and you won't be sad anymore.",
		videoLink: '675688953?h=6e489ff4e9',
		imgFile: 'jada-and-sabrina',
		proPath: '/profile/jada-and-sabrina',
		suppQA: [
			{
				id: 0,
				question: 'You two have known each other for a long time. How did become friends?',
				answer: (
					<Fragment>
						<p>Sabrina: We both ended up at the same club team and instantly connected. I committed to Arizona my sophomore year, and when coaches asked for my help recruiting, I was like, "Man, it'd be really cool if my best friend could come to college with me." After Jada visited, she ended up committing. She told the coach, "I'm not coming if I'm not rooming with Sabrina."</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What do you think it was that you liked about the school and why did it feel like a fit?',
				answer: (
					<Fragment>
						<p>Sabrina: The coaches made me feel at home. The first time I stepped foot on this campus, I felt like this is where I'm going to be. They showed me around and everything was so pretty.</p>
						<p>Jada: I took my visit and it was like nothing I'd ever seen before. I came to Arizona thinking it's just going to be cactus and dirt everywhere, and it wasn't like that. There's a lot that I just didn't know until I came here – it opened my eyes to how great the school is.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: "What's the one thing that motivates you?",
				answer: (
					<Fragment>
						<p>Sabrina: I am a first-generation student, so I think that's what really pushes me to not only be a student, but to be a student athlete. My family is what drives me to be the best person, student, anything that could be.</p>
						<p>Jada: I would say my family, too. I'm actually the opposite, so everyone in my family went to college. I have a lot of pressure on me to do well, and it drives me. Sabrina and I are different in our situations, but the driving factor is family.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: "Teammates on the field, best friends off the field. What's the difference between being a teammate and being a friend?",
				answer: (
					<Fragment>
						<p>Jada: Being a good friend is being there for other people. You're not at home anymore. College is completely different. So, if you have somebody who's there for you and you feel at home with them, you should keep them around.</p>
						<p>Sabrina: I feel emotional support is a very big one. I'm down, she helps me. If she's down, I help her.</p>
					</Fragment>
				)
			},
			{
				id: 4,
				question: 'If you had to choose word that would describe the two of you, what would it be?',
				answer: (
					<Fragment>
						<p>Sabrina: I feel like we're a mixture of crazy and funny. But, we entertain.</p>
						<p>Jada: Ca-funny? Crunny?</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 4,
		firstName: 'Jessy',
		descrip: 'The Enthusiast',
		year: 'Class of 2021',
		major: 'BIOCHEMISTRY, MOLECULAR & CELLULAR BIOLOGY, SPANISH AND PORTUGUESE',
		homeCity: 'Medford',
		homeState: 'NJ',
		studentMetaDescription: 'Jessy studied Biochemistry, Molecular and Cellular Biology, Spanish and Portuguese at the University of Arizona. She is the enthusiast; her variety of interests has made for one-of-a-kind college experience. Get to know her contributions to NASA’s OSIRIS-Rex mission her We Are Wildcats student profile.',
		studentSummary: 'Part scientist, part linguist, part cheerleader, part astronomer – Jessy’s variety of interests has made for a one-of-a-kind college experience, across the world and universe.',
		pullQuote: 'I’m really excited that I’m a senior and I’m ready to go out into the world, to actually pursue the goals that I have for myself, and to better the people and the places that I encounter.',
		videoLink: '675690330?h=7d9e0bb75b',
		imgFile: 'jessy',
		proPath: '/profile/jessy',
		suppQA: [
			{
				id: 0,
				question: 'What did you learn from volunteering on NASA’s OSIRIS-REx mission?',
				answer: (
					<Fragment>
						<p>I learned that it’s ok to not know everything, you just have to try and you have to at least question why things are the way that they are and push yourself to find out the answers. A lot of things on the mission and a lot of the things in my research, even Dante Lauretta or some of his colleagues don’t understand their active research. That’s where innovation begins. Discovering that has been really important in my growth as a student and as a researcher.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'How have you made the most of your time at Arizona?',
				answer: (
					<Fragment>
						<p>If I walk into a school and they have a NASA mission, why not put myself out there try to get involved even as a volunteer? If I loved cheerleading in high school, why not try out for the team? You typically only have four years of college, and it’s on you to do everything you can and take advantage of all the opportunities. I’ve strived to do that every day while I’ve been here.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What three words would you use to describe yourself?',
				answer: (
					<Fragment>
						<p>“Doing my best.” Looking back on my first year, I never would have thought I would be involved in all the things I’m involved in. I’ve really put myself out there and gotten out of my comfort zone and tried new things that I wouldn’t have otherwise. I really just did my best.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'How do you encourage others to take advantage of their time in college?',
				answer: (
					<Fragment>
						<p>College is about a lot more than what your GPA is; it’s about who you are and how you come out of it. Your perspectives, your opinions and what you do for others. I didn’t just want to come out of my time at the University of Arizona with good grades that I could put on résumé, I wanted to come out as a person with practical skills and ready to take on the world.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 5,
		firstName: 'Katie',
		descrip: 'The Original',
		year: 'Class of 2021',
		major: 'Harp Performance and Russian Language',
		homeCity: 'Park City',
		homeState: 'UT',
		studentMetaDescription: 'Katie studied Harp Performance and Russian Language at the University of Arizona. She is the original, enthusiastic about sharing music with others. Learn about her college experience on her We Are Arizona student profile.',
		studentSummary: 'It’s hard not to smile when you’re around Katie, whether it’s due to her contagious passion for the harp, her roll-with-the-punches positivity, or her enthusiasm for sharing what she loves with others.',
		pullQuote: "Music is a feeling of pure joy. I love that it encompasses every sense we have. Music is very visual. Obviously, it's auditory. But it's also very kinesthetic as well. Especially with the harp, I can feel every note that I'm playing.",
		videoLink: '675691554?h=2b67e6cc46',
		imgFile: 'katie',
		proPath: '/profile/katie',
		suppQA: [
			{
				id: 0,
				question: 'How did you discover the harp and what role has it played in your life?',
				answer: (
					<Fragment>
						<p>I was so young I don't actually remember any of it, but the way my mother tells it is that I was a week short of 13 months old. We went to my mom's cousin's wedding and they had a harpist at the reception. Little baby me crawled up to that stage, stuck her baby chin over the top, and proceeded to not move. My mom says that people were staring at the baby staring at the harp more than they were staring at the harp.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What do you love the most about music?',
				answer: (
					<Fragment>
						<p>There are so many great stories. I have a friend who plays the harp and works with students with disabilities, and she will bring her deaf students into the harp room and have them touch the harp. And they may not be able to hear it, but they can have a deep musical experience through touching the board as she's playing.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What have you learned about yourself in college?',
				answer: (
					<Fragment>
						<p>If anything, my college experience has solidified who I am. It's taught me some moral values that I didn't know I had, and it's taught me that accepting whatever life throws at me is okay, even if it's very much not what I planned.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What do you think has been the most rewarding part about being a college student so far?',
				answer: (
					<Fragment>
						<p>The most rewarding part of being a college student is getting to experience a huge number of opportunities. It's been amazing to see the world through many different lenses.</p>
					</Fragment>
				)
			},
			{
				id: 4,
				question: "What's it like when you're totally enraptured in the performance?",
				answer: (
					<Fragment>
						<p>Being totally enraptured in a performance is knowing exactly what comes next – not having to think about what my fingers or feet are doing, just letting the music happen. There's maybe a slight sense of awareness of the audience, but it's about letting it happen. Any mistakes? No big deal. It's great.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 6,
		firstName: 'Kelsey',
		descrip: 'The Ally',
		year: 'Class of 2021',
		major: 'Sociology and Gender & Women’s Studies',
		homeCity: 'Peoria',
		homeState: 'AZ',
		studentMetaDescription: 'Kelsey studied Sociology and Gender & Women’s Studies at the University of Arizona. She is the ally, creating her own Wildcat path. Learn what her favorite thing was about being a college student on her We Are Arizona student profile.',
		studentSummary: 'While following in the footsteps of generations of Wildcats before her, Kelsey has managed to create her own path.',
		pullQuote: 'The most exciting thing about being a college student has been meeting as many different people as possible; they all come from different walks of life.',
		videoLink: '675692690?h=b1af617b6e',
		imgFile: 'kelsey',
		proPath: '/profile/kelsey',
		suppQA: [
			{
				id: 0,
				question: 'You found your community; how can future Wildcats find theirs?',
				answer: (
					<Fragment>
						<p>I would highly recommend going into any cultural center – they’re very open and super accepting. I spend a lot of time in the Women and Gender Resource center; I’m always there greeting people. It’s a really nice space for people to come in, ask questions and just hang out.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'How has your perspective changed in college?',
				answer: (
					<Fragment>
						<p>The thing I’ve learned most about myself during college was that it’s ok to be different. In my fields of study, we talk about so many different identities and what it’s like even holding multiple identities. That’s what has really taught me that being different is okay and that we should celebrate the differences of all.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'How has being a third-generation Wildcat prepared you for life at Arizona?',
				answer: (
					<Fragment>
						<p>For me, being third-generation means so much more than just the title of being a third-generation Wildcat – it’s all about the experiences. I’ve made many memories here with my family.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'Your mom took you to classes when she was pursuing her degree – what was that like?',
				answer: (
					<Fragment>
						<p>My mom finishing her degree as a teen mom really made me want to do better. It made me want to do more, be more involved and create a sense of purpose.</p>
						<p>I sat in classes with her, and I do remember a little bit of what that was like. My mom handled it amazingly, and had professors who were super accepting of a mother who was trying to better her life for her child.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 7,
		firstName: 'Mandy',
		descrip: 'The Global Citizen',
		year: 'Class of 2021',
		major: 'German Studies, Industrial Engineering; Spanish (minor)',
		homeCity: 'Tianjin',
		homeState: 'China',
		studentMetaDescription: 'Mandy double-majored in German Studies and Industrial Engineering and minored in Spanish at the University of Arizona. She is a Global Citizen and has studied abroad in various countries. Learn about her experience being a college student on her We Are Arizona profile.',
		studentSummary: 'Mandy has journeyed from China to the United States and Germany and Spain with the University of Arizona, all in the pursuit of connection and understanding people and cultures.',
		pullQuote: 'I want to bring people together to collaborate, to create great things together.',
		videoLink: '675693619?h=ec4e85b6c1',
		imgFile: 'mandy',
		proPath: '/profile/mandy',
		suppQA: [
			{
				id: 0,
				question: 'What have you learned about yourself since coming to school and being in college?',
				answer: (
					<Fragment>
						<p>Where my passion is, because I have so many interests. I'm interested in STEM, I’m interested in humanities, but my true passion is people – to encourage them, to inspire them and to learn from different cultures, different languages, and then combine those things. Maybe it's my job to bring people together to have conversations.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: "How does that make you feel when you're bringing people together and creating those conversations?",
				answer: (
					<Fragment>
						<p>I also feel inspired because you never know what you can get from a conversation. Maybe you get a new idea. Maybe you learn something from how others talk. Maybe you learn for how to support others. All of those new ideas sparkle every day.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'How have you changed after studying abroad so many times?',
				answer: (
					<Fragment>
						<p>Studying abroad has given me a new perspective. I’m more sympathetic towards others. I dealt with culture shock when studying abroad and when I came back to Tucson. I felt like I didn’t belong anywhere, so I can really relate to people who feel like they don’t belong. I can reach out to them to be their friend and to understand their struggle. That’s the rewarding part of going through those challenges.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: "The world's a changing place – what do you think it means to be a college student in the year 2020?",
				answer: (
					<Fragment>
						<p>Being a college student in this ever-changing world is an amazing adventure. You don't know what's waiting for you. Even if the journey is maybe a little bit bumpy, after you conquer that, you gain strength, you can new perspective, new vision, and then you bring new elements, new perspective to contribute to this diverse world.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 8,
		firstName: 'Mariana',
		descrip: 'The Empath',
		year: 'Class of 2021',
		major: 'Biology and Spanish',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Mariana studied Biology and Spanish at the University of Arizona. She is an empath and a future physician with a passion for helping others. Find out what the most exciting part of being a college senior was on her We Are Wildcats student profile.',
		studentSummary: 'Not all the skills needed to become a doctor can be taught in school. Future physician Mariana brings empathy and warmth to patients in need.',
		pullQuote: 'I was raised to help others; it was foundational in my interest in medicine. My volunteer roles have reaffirmed why I wanted to go into medicine, and have driven my continued efforts toward that role.',
		videoLink: '675694479?h=ec354458b1',
		imgFile: 'mariana',
		proPath: '/profile/mariana',
		suppQA: [
			{
				id: 0,
				question: 'What are some issues you’ve learned about through volunteering?',
				answer: (
					<Fragment>
						<p>[Clinica Amistad] is a free clinic, so a major disparity that we address is lack of access to health care because of low socio-economic opportunity. We also address linguistic disparity in health care. Even in Southern Arizona, it’s very common to not have translation services or Spanish-speaking physicians. At the clinic, we’re able to provide a lot of translation and Spanish-speaking providers and volunteers that the patients need.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'How does your empathetic nature help you when volunteering? ',
				answer: (
					<Fragment>
						<p>Empathy is what really helps me to make decisions, and it helps me to establish patient rapport when I’m speaking to someone who I just met. It also helps me consider: What it is that the patient might appreciate, or what might be helpful for the patient in their situation?</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What are you excited for this year?',
				answer: (
					<Fragment>
						<p>In 2020, being a college student, especially being a college senior, is very exciting. We’re at the frontier of medicine and healthcare. Being on the verge of graduation and pursuing a professional degree in 2020 feels very exciting and very hopeful.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What advice have you kept with you throughout college?',
				answer: (
					<Fragment>
						<p>The best piece of advice I’ve gotten is to take advantage of college opportunities early, especially as a first-year. The time goes by very quickly, and some opportunities may pass you by if you don’t take advantage as soon as possible.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 9,
		firstName: 'Marissa',
		descrip: 'The Environmentalist',
		year: 'Sophomore',
		major: 'Bioinformatics; Chemistry (minor)',
		homeCity: 'Ringoes',
		homeState: 'NJ',
		studentMetaDescription: 'Marissa is a Sophomore majoring in Bioinformatics and minoring in Chemistry at the University of Arizona. She is the environmentalist, exploring Tucson from desert valleys to mountain peaks. Find out what inspires her research on her We Are Arizona student profile.',
		studentSummary: 'You’ll likely find Marissa off the beaten path, exploring Tucson from desert valleys to mountain peaks. The great outdoors inspires her research to use technology to benefit nature.',
		pullQuote: "A lot can go wrong in nature from our perspectives, but then you start to realize that even though it could have possibly hurt me, it's something that actually benefited nature. You wouldn't really want to be caught in a blizzard, but that’s how that ecosystem functions.",
		videoLink: '675700415?h=97d7999f49',
		imgFile: 'marissa',
		proPath: '/profile/marissa',
		suppQA: [
			{
				id: 0,
				question: 'What are you most passionate about?',
				answer: (
					<Fragment>
						<p>I'm most passionate about human interaction with the natural world. I grew up on a farm, and I’ve always loved being outside. I appreciate the positive relationship humans can have with the environment. I hope to further push a positive relationship from human to earth, earth to human.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'How did you take that inspiration and apply it to what you’re studying?',
				answer: (
					<Fragment>
						<p>I want to couple technology and the natural world. A lot of people think those spheres aren't exactly comfortable with each other, because technology represents distance from nature. But I think that you can actually use the technology to benefit nature and vice versa.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: "What role has the natural world played in shaping your worldview? How does that influence what you're studying?",
				answer: (
					<Fragment>
						<p>Spending a lot of time outside has made me realize that when you're presented with a situation, you should be looking at the good in it. It's made me a more positive thinker and definitely more creative at problem solving, since the best solution is not always the most apparent solution.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'Do you have a motto?',
				answer: (
					<Fragment>
						<p>My personal motto is to set my goals as high as possible, while still being open and okay with failure.</p>
					</Fragment>
				)
			},
			{
				id: 4,
				question: 'What do you think has been the most rewarding part about being a college student?',
				answer: (
					<Fragment>
						<p>To be amongst my peers. It's really inspiring to see all of the fields that they're working in; all of the amazing ambitions that they have. It’s been amazingly rewarding to have my own curiosities, but then also see my friends and so many amazing classmates doing things I never would have thought of.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 10,
		firstName: 'Max',
		descrip: 'The Stargazer',
		year: 'First-year',
		major: 'Physics and Astronomy',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Max is a first-year Physics and Astronomy student at the University of Arizona. He is the Stargazer, college student by day and rock star by night. Find out how he inspires others through music on his We Are Arizona profile.',
		studentSummary: 'College student by day, rock star by night. Max pulls equal inspiration from the universe as he does from artists like Freddie Mercury and David Bowie.',
		pullQuote: 'I use my creative side to inspire new forms of problem solving – that’s ultimately what the greatest physicists do. More often times, surprisingly enough, the science side inspires my creative side.',
		videoLink: '675696105?h=416de777a6',
		imgFile: 'max',
		proPath: '/profile/max',
		suppQA: [
			{
				id: 0,
				question: 'What are you most passionate about?',
				answer: (
					<Fragment>
						<p>Inspiring passion in other people; the way that I’m able to do that is through music, primarily. I’m choosing physics and astronomy as my majors because to me, physics is the gateway for understanding big cosmological problems, which can inspire lots of people.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What have you loved about being at the University of Arizona so far?',
				answer: (
					<Fragment>
						<p>The most rewarding part about being a college student has to be all of the new information that I’m learning. And while I’m in a lot of GenEds right now, I look forward to the physics classes and math classes that are above the fundamentals, where I can start to understand the theory and the stuff that we haven’t really explored yet.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'How do you combine your scientific and musical sides?',
				answer: (
					<Fragment>
						<p>A lot of the stuff that I see in science is so inherently interesting. Nature has presented this phenomenon to us and there are a lot of parallels in these phenomena to emotional aspects that I can tie together in music.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What does it mean to be a college student in 2020?',
				answer: (
					<Fragment>
						<p>To be a college student at this time is to be a new wave of younger people who are educated, and who can make informed decisions on what they want their future to look like.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 11,
		firstName: 'Nicole',
		descrip: 'The Champion',
		year: 'Junior',
		major: 'Psychology',
		homeCity: 'Philadelphia',
		homeState: 'PA',
		studentMetaDescription: 'Nicole is a Junior studying Psychology at the University of Arizona. She is the champion, aiming to change the future of the next generation. Learn how she inspires her kids on her We Are Arizona student profile.',
		studentSummary: 'Empowered by the idea that science can improve the lives of underserved communities, Nicole aims to change the next generation’s future – and inspire her four children along the way.',
		pullQuote: `We can change how people think. We don't have to just label somebody – "he's a bad kid, he's a troublemaker, he got kicked out of school” – and toss them away.`,
		videoLink: '675697857?h=f327bd57a2',
		imgFile: 'nicole',
		proPath: '/profile/nicole',
		suppQA: [
			{
				id: 0,
				question: 'What has been the most rewarding part about being a student so far?',
				answer: (
					<Fragment>
						<p>Being able to share the experience with my kids, and seeing them get excited about going to the University of Arizona one day. They've been more likely to share the stuff going on at school with them, when I come home talking about school. It's not the typical answers like “my day was fine,” or whatever.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What’s the purpose behind your research?',
				answer: (
					<Fragment>
						<p>One of the last things to develop [in young adults] is the prefrontal cortex, which is logic and reasoning. We've got to consider that the way juveniles who commit crimes are processing information is different. I would like to take it a step further and see how trauma may or may not impact brain development and if that's something that we can reverse. If trauma does impact brain development, what does it mean for juveniles? I don't want to live in a country full of people who have just been tossed aside.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What have you learned about yourself by coming back to school and getting involved in research?',
				answer: (
					<Fragment>
						<p>It's never too late. People used to say that to me, but I never really understood it. I used to think about all the time I wasted not going to school, but then I went back to school. It's really never too late to do something that you want to do and be good at it.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What keeps you motivated?',
				answer: (
					<Fragment>
						<p>My biggest passion in life – aside from my kids – is connecting with people who are underserved. I really like working with kids, specifically kids that are going through trauma at home. I just feel like it's really important to always have your roots in your community, and to give a voice to people who don't have that. Underserved populations need people in their community to advocate for them.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 12,
		firstName: 'Noah',
		descrip: 'The Communicator',
		year: 'Junior',
		major: 'Dance, East Asian Studies (Japanese language emphasis); Pre-Health (minor)',
		homeCity: 'Spokane',
		homeState: 'WA',
		studentMetaDescription: 'Noah is a Junior majoring in Dance, East Asian Studies (Japanese language emphasis) with a minor in Pre-Health at the University of Arizona. He is the communicator, looking for ways to connect with others. Find out his biggest challenge finding his degree pathway on his We Are Arizona student profile.',
		studentSummary: 'To Noah, there are a variety of ways to have a conversation – it might be spoken, shared through dance or in a different language entirely. The goal always remains the same: connection.',
		pullQuote: 'I see medicine as an art form, and I see dance as a language. Dance is a way of gauging my audience, and learning to be cognizant of what they’re looking for. I’m hoping to take the [communication] skills I learned through dance to present a problem or a situation to medical patients, in a way that has tactfulness and clarity.',
		videoLink: '675698950?h=e797db1cb1',
		imgFile: 'noah',
		proPath: '/profile/noah',
		suppQA: [
			{
				id: 0,
				question: 'What challenges do you face with your different areas of study?',
				answer: (
					<Fragment>
						<p>The most challenging thing has been my degree pathway. I wouldn’t choose to do anything else, and I wouldn’t have done anything differently, but it’s definitely been hard at times. I’ve had to take 25 units or more, while balancing that with extracurriculars, club activities, my personal life and more. It’s been challenging, but I’ve been able to do so much.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What have you learned from your college experience so far?',
				answer: (
					<Fragment>
						<p>I continue to surprise myself. Oftentimes, I feel like I won’t be able to accomplish whatever’s set out in front of me. I’m always thinking I’m going to fail; I become a bit self-defeating. It’s been really terrific that I’ve been able to keep surprising myself and keep doing well and keep moving forward, even when everything’s stacked up against me.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'What made you decide to come to the University of Arizona?',
				answer: (
					<Fragment>
						<p>I didn’t want to set any limits on myself when I went to college. For me the most surprising thing about college was how limitless pretty much anything was here. I can pretty much do anything I want and the opportunities are endless in terms of being able to combine majors, degrees, minors, interests, club activities, internships – all of that. And then just the resources that we have here; it makes for a really amazing environment for studying or exploring whatever you want to study, whether that be something academic and professional or something artistic or a self-interest.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What are some things future college students should take into consideration?',
				answer: (
					<Fragment>
						<p>College is definitely a time to enjoy life, explore and be happy with the moment that you’re in. I came into college not necessarily looking solely to focus on medicine; college is that time to do whatever you see fit. </p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 13,
		firstName: 'Patrick',
		descrip: 'The Change Maker',
		year: 'First-year',
		major: 'Public Management & Policy',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Patrick is a first-year Public Management & Policy major at the University of Arizona. He is the change maker, not afraid to tackle monumental change. Find out the path he’s setting for future Latinx students on his We Are Arizona profile.',
		studentSummary: 'Patrick’s not afraid to tackle monumental change. In high school, he fought for his community by advocating for greater youth representation in local government and increased funding for public schools. In college, he fights for the future.',
		pullQuote: "My personal definition of success is knowing that when you moved up in the world, you also brought people up with you. That's when you know that you're successful.",
		videoLink: '675701284?h=7c708bf745',
		imgFile: 'patrick',
		proPath: '/profile/patrick',
		suppQA: [
			{
				id: 0,
				question: "What's the best piece of advice you've ever gotten?",
				answer: (
					<Fragment>
						<p>The best piece of advice that I've ever gotten was being told that the two best days in your life are the day you were born and the day you figure out why. And I believe I figured out why. I'm here to make a positive difference in this world.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What are you most passionate about?',
				answer: (
					<Fragment>
						<p>When I walk on campus, I feel a sense of responsibility for the generations behind me who have fought so that other Latinx students can get a college education. When I find myself in a stressful situation, I think about those who fought so that I can be here. That makes me want to work even harder.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'Why do you believe so strongly in the power of community? ',
				answer: (
					<Fragment>
						<p>This world is not going to change by the decision of one person. It takes everybody as a whole, collectively coming together to have those conversations that we don't really want to have. The power of community means looking out for one another.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What are the things that inspire you the most?',
				answer: (
					<Fragment>
						<p>Knowing that I'm a trailblazer. So as long as you keep on fighting and keep on going to school, you're setting the path for future generations of Latinx students, and sending a huge thank you to the generations before.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 14,
		firstName: 'Remy',
		descrip: 'The World Traveler',
		year: 'Junior',
		major: 'French, Arabic and Political Science',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Remy is a Junior studying French, Arabic and Political Science at the University of Arizona. He is the world traveler, spending time studying abroad in Paris and learning to thrive in new environments.  Find out where he is going next on his We Are Arizona student profile.',
		studentSummary: 'From a small high school to a large university, and from Tucson to France and Morocco, Remy has discovered that taking risks can open up a world of opportunity.',
		pullQuote: 'Travel has taught me that I’m a lot more confident than I think I am. I’m really adaptive and with the knowledge that I have, I can thrive in environments that are unfamiliar to me.',
		videoLink: '675702431?h=25823ad977',
		imgFile: 'remy',
		proPath: '/profile/remy',
		suppQA: [
			{
				id: 0,
				question: 'You went to a smaller high school – how did the University of Arizona compare?',
				answer: (
					<Fragment>
						<p>The transition to the University of Arizona was definitely very difficult for me. Coming from 40 people to like 40,000 people was a really big adjustment. I lived in the dorms my first year, so being on campus and being surrounded by people who were in the same boat was really helpful toward understanding that everyone's having the same issues.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'Tell us about studying in Paris',
				answer: (
					<Fragment>
						<p>I spent six weeks in Paris through "Arizona in Paris" with the French department. I lived with a little old lady right in the middle of Paris and I had a roommate also from the university. That was a really rewarding experience, just because that was the first time I actually traveled alone. I had never been on a plane by myself before. I learned a lot about French culture. It was also cool to see how my host thought we lived in Tucson. She was convinced that I rode my horse everywhere.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'Where are you going next?',
				answer: (
					<Fragment>
						<p>I am currently preparing to test for my summer abroad in Morocco, through the Arizona Arabic Flagship Program.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'How has studying and traveling abroad influenced your view of the world?',
				answer: (
					<Fragment>
						<p>Study abroad really showed me how you can study everything that you want in the classroom, but it's much more natural being a part of it. It also taught me that people value genuine connection, and having intercultural competence and language skills just makes everyone a lot more warm and welcoming to you. Everyone values when you can relate to someone, even if it's in the smallest way possible.</p>
					</Fragment>
				)
			},
			{
				id: 4,
				question: 'What has travel taught you about yourself?',
				answer: (
					<Fragment>
						<p>Travel has given me the confidence to know that I can go into a foreign environment and I can survive and thrive and really enjoy myself. Traveling is really reassuring, because I know I’ll find something that I love to do and I can be successful no matter where I'm placed in the world.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 15,
		firstName: 'Robert',
		descrip: 'The Innovator',
		year: 'Class of 2021',
		major: 'Africana Studies; Digital Humanities (minor)',
		homeCity: 'Nogales',
		homeState: 'AZ',
		studentMetaDescription: 'Robert majored in Africana Studies and minored in Digital Humanities at the University of Arizona. He is the innovator, challenging himself to think differently. Find out what being a college student meant to him on his We Are Arizona profile.',
		studentSummary: 'Robert uses his skills as a technologist to solve problems and connect the world. His friendly personality and ability to connect and share in person, make Robert stand out from the crowd.',
		pullQuote: "I believe technology has the power to bring people together. I study humanities because technology alone is not the answer. When you marry technology with the arts, that's what makes us more human – and that’s how the world can be a lot better.",
		videoLink: '675703553?h=ffacda6856',
		imgFile: 'robert',
		proPath: '/profile/robert',
		suppQA: [
			{
				id: 0,
				question: 'Since coming to the University of Arizona, what do you think has been the biggest surprise for you as a student?',
				answer: (
					<Fragment>
						<p>I didn't know what to expect of the people I'd meet. I came in thinking about what I had to do to be the best. The people around me taught me that it's about working together and working on something bigger than ourselves. It’s more than just being better at something – it's being better collaboratively.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'How has collaboration kept you motivated?',
				answer: (
					<Fragment>
						<p>Me and my friends, we built an app to solve a problem – and it worked! We solved something! We made something even greater. We share all our ideas, and by doing that, other people also get inspired. It gives me fulfillment. I feel like I have a purpose for something, so that's how I stay motivated.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'How did you find your community at the University of Arizona?',
				answer: (
					<Fragment>
						<p>Through the hackathon and the Innovate UA group. They shared their passions with me, and I also did the same with them. It was a group mindset where we said, "Hey, we care about something really important: We really care about changing the way we do technology," and we had a mission. That mission bonded a lot of us and that was my community. My community was the video gamers, the hackathoners – the people who wanted to change it, who wanted to bring something to it.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What do you think it means to be a college student at this time in history?',
				answer: (
					<Fragment>
						<p>Being a college student is kind of funny now. There's so much to look forward to. You have to be curious. You have to be open. You have to challenge yourself to think differently, to look at a problem at a different level, perspective, and shape and say, "What is this really? Why is this a problem? How can this be solved? What can I do? What can others do?" </p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 16,
		firstName: 'Rose',
		descrip: 'The Thinker',
		year: 'First-year',
		major: 'Pre-Neuroscience & Cognitive Science',
		homeCity: 'Tucson',
		homeState: 'AZ',
		studentMetaDescription: 'Rose is a first-year Pre-Neuroscience & Cognitive Science major at the University of Arizona. She is the thinker and likes to tackle big questions while inspiring joy in those around her. Find out how she aims to make the world a more intentional place on her We Are Wildcats student profile.',
		studentSummary: 'Rose likes to tackle big questions. What makes us tick? How can we better understand the human mind? Combined with her delight in inspiring joy in those around her, Rose aims to make the world a more intentional place.',
		pullQuote: "You live so much more when you're in college. People say that it's the best time of their lives – it's actually true. Every second, there's always something happening. You're always thinking.",
		videoLink: '675704351?h=3280c2deaf',
		imgFile: 'rose',
		proPath: '/profile/rose',
		suppQA: [
			{
				id: 0,
				question: 'What do you hope to learn or understand by studying the brain?',
				answer: (
					<Fragment>
						<p>I want to understand what makes us tick. Why do people do things the way they do? Why is society built the way it is? I want to understand people, then maybe I can change things; maybe I can just change myself and live the life that I want to live.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What organizations are you involved in?',
				answer: (
					<Fragment>
						<p>I'm involved in ASEMS [Arizona Science, Engineering and Math Scholars Program], which helps students who are low income, first generation or have had something else hold them back to make sure they go through STEM well. I found amazing support so far. Sometimes we need that little push to keep us going.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: "Do you feel like you're more in charge than you were before?",
				answer: (
					<Fragment>
						<p>Definitely, definitely. I have the power of my classwork and what I'm doing, and I got to drop a class and change my major because I didn't like it. Couldn't do that in high school or anytime beforehand!</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What do you think it means to be a college student in the year 2020?',
				answer: (
					<Fragment>
						<p>You could probably teach yourself a lot of your courses online, but that's not what it's about. It's about the fact that you're going from wherever you were before to a completely new place, and growing yourself and actually finding out who you want to be. And you have people, lots of people, to guide you and lots of resources so you can choose the path of where you want to go and what you want to do.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	},
	{
		id: 17,
		firstName: 'Will',
		descrip: 'The Helping Hand',
		year: 'Sophomore',
		major: 'PHILOSOPHY, POLITICS, ECONOMICS & LAW (PPEL) AND POLITICAL SCIENCE; SPANISH (MINOR)',
		homeCity: 'Manhattan Beach',
		homeState: 'CA',
		studentMetaDescription: 'Will is a Sophomore majoring in Philosophy, Politics, Economics and Law (PPEL) and Political Science with a minor in Spanish at the University of Arizona. He is the helping hand, inspiring the people around him to pursue their dreams. Find out what the most rewarding part about being a college student is for him on his We Are Wildcats student profile.',
		studentSummary: 'Will is equally motivated and a motivator, inspiring the people around him to pursue their dreams while working to accomplish his own.',
		pullQuote: "You really have to remind yourself that we're all the same in one way or another. You may not agree with everyone all the time, but that doesn't mean that others are wrong, or wrong for thinking what they think.",
		videoLink: '675704803?h=4f812eebb7',
		imgFile: 'will',
		proPath: '/profile/will',
		suppQA: [
			{
				id: 0,
				question: 'What’s your motto?',
				answer: (
					<Fragment>
						<p>Be inconspicuously conspicuous. Meaning to be conspicuous in the way that you're changing the world. Do your best to accomplish what you want to accomplish for yourself and for others, but be inconspicuous about it in that you're not taking everybody's attention and diverting it to yourself.</p>
					</Fragment>
				)
			},
			{
				id: 1,
				question: 'What’s been the most rewarding part about being a college student?',
				answer: (
					<Fragment>
						<p>Seeing all the hard work I put in, and the results that I get from it. I don't just write something down and then get a grade at the end of the day. I work hard in my classes, I work hard in the organizations I'm a part of and I get to see the results that benefit me, the school and hopefully a lot more people too.</p>
					</Fragment>
				)
			},
			{
				id: 2,
				question: 'Tell us about your volunteering experiences',
				answer: (
					<Fragment>
						<p>I've been involved in an organization called, "Inalienable," for almost six years now. Our main goal is to get certificates to show who these migrants are and what they know, for all of San Joaquin in Baja California Sur. We’ve made huge strides in proving that these migrants have human dignity, and that everyone has human dignity.</p>
					</Fragment>
				)
			},
			{
				id: 3,
				question: 'What are you hoping to take from those experiences and how are you planning to use that in the future?',
				answer: (
					<Fragment>
						<p>The main thing is that we're all the same in one way or another. And if you work together, you can do so much more. I feel that the things that I've learned have helped me have a nice worldly view on life, instead of just being in my bubble thinking things should be the way I think they should be.</p>
					</Fragment>
				)
			}
		],
		metaData: {
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
			keywords: `Lorem, ipsum, Lorem, ipsum`
		}
	}
	// {
	//     id: 4,
	//     firstName: "",
	//     descrip: "",
	//     year: "",
	//     major: "",
	//     homeCity: "",
	//     homeState: "",
	//     studentSummary: "",
	//     pullQuote: "",
	//     videoLink: "",
	//     imgFile: "",
	//     proPath: "/profile/",
	//     suppQA: [
	//         {
	//             id: 1,
	//             question: "",
	//             answer: (
	//                 <Fragment>
	//                      <p></p>
	//                 </Fragment>
	//             )
	//         },
	//         {
	//             id: 2,
	//             question: "",
	//             answer: (
	//                 <Fragment>
	//                      <p></p>
	//                 </Fragment>
	//             )
	//         },
	//         {
	//             id: 3,
	//             question: "",
	//             answer: (
	//                 <Fragment>
	//                      <p></p>
	//                 </Fragment>
	//             )
	//         },
	//         {
	//             id: 4,
	//             question: "",
	//             answer: (
	//                 <Fragment>
	//                      <p></p>
	//                 </Fragment>
	//             )
	//         },
	//         {
	//             id: 5,
	//             question: "",
	//             answer: (
	//                 <Fragment>
	//                      <p></p>
	//                 </Fragment>
	//             )
	//         }
	//     ],
	//     metaData: {
	//          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
	//          keywords: `Lorem, ipsum, Lorem, ipsum`
	//     }
	// }
];

export default profiles;
