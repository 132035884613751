import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { HomeHero, HomeContent, HomeProfile } from '../../components';
import { useInView } from 'react-intersection-observer';
import Style from './Home.css';

const Home = props => {
	const height = window.innerHeight - 94;
	const [ref, inView] = useInView({
		threshold: 0,
		rootMargin: '0px 0px -' + height + 'px 0px'
	});

	useEffect(() => {
		if (inView) {
			props.reverseIcons('blue');
		}
	}, [inView]);
	return (
		<div style={Style.Home} className="home_section sticky">
			<Helmet>
				<title>We Are Wildcats | The University of Arizona, Tucson, Arizona</title>
				<meta name="description" content="Hear standout University of Arizona student testimonials of how our bright young leaders lead positive change, create original paths and embrace the exciting unknown." />
			</Helmet>
			<HomeHero iconRev={props.iconRev} reverseIcons={props.reverseIcons} />
			<div ref={ref}>
				<HomeContent />
				<div className="home_profiles">
					{props.allStudents.map(student => (
						<HomeProfile key={student.id} studentData={student} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Home;
