import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollToTop } from './components';
import './index.css';
import App from './App';
import Profiles from './data/Profiles';
import * as serviceWorker from './serviceWorker';

let newOrder = Object.values(Profiles);
for (let i = newOrder.length - 1; i > 0; i--) {
	const j = Math.floor(Math.random() * (i + 1));
	[newOrder[i], newOrder[j]] = [newOrder[j], newOrder[i]];
}

ReactDOM.render(
	<Router>
		<ScrollToTop />
		<App randomProfiles={newOrder} orderedProfiles={Profiles} />
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
