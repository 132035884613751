import React from 'react';
import Style from './ProfileQA.css';

const ProfileQA = props => {
	return (
		<section style={Style.ProfileQA} className="profile_qa">
			<div className="wrapper">
				<div className="hidden_text">Q&amp;A</div>
				<div className="questions_ans">
					<ul>
						{props.questions.map(question => (
							<li key={question.id}>
								<strong>{question.question}</strong>
								<span>{question.answer}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default ProfileQA;
