import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import Style from './HomeProfile.css';

const HomeProfile = props => {
	const student = props.studentData;

	const [ref, inView] = useInView({
		rootMargin: '-100px 0px'
	});

	const [quoteRef, quoteInView] = useInView({
		rootMargin: '-100px 0px'
	});
	return (
		<Link ref={ref} to={student.proPath} id={`home-page-${student.firstName}`} style={Style.HomeProfile} className={`home_profile ${inView ? 'in_view' : ''}`}>
			<span className="student_name">{student.firstName.toUpperCase()}</span>
			<div className="student_info">
				<div className="student_img_cont">
					<img src={require(`../../img/home-profile/${student.imgFile}.jpg`)} alt={student.firstName} />
				</div>
				<span className="student_descrip">{student.descrip.toUpperCase()}</span>
				<div ref={quoteRef} className={`student_quote_cont ${quoteInView ? 'quote_in_view' : ''}`}>
					<span>{student.pullQuote}</span>
				</div>
			</div>
		</Link>
	);
};

export default HomeProfile;
