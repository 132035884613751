import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Style from './HomeHero.css';

const HomeHero = props => {
	const height = window.innerHeight - 94;
	const [ref, inView] = useInView({
		threshold: 0,
		rootMargin: '0px 0px -' + height + 'px 0px'
	});
	useEffect(() => {
		if (inView) {
			props.reverseIcons('white');
		}
	}, [inView]);
	return (
		<div ref={ref} style={Style.HomeHero} className="home_hero">
			<h1>
				<span className="small_text">WE ARE</span>
				<span className="large_text">
					WILDCAT<span>S</span>
				</span>
				<div className="mouse_wave">
					<span className="scroll_arrows one"></span>
					<span className="scroll_arrows two"></span>
					<span className="scroll_arrows three"></span>
				</div>
			</h1>
		</div>
	);
};

export default HomeHero;
