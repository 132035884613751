import React from 'react';
import { ProfileVideo, ProfileQA } from '../../components';
import Style from './ProfileBody.css';

const ProfileBody = props => {
	return (
		<div style={Style.ProfileBody} className="profile_body sticky">
			<ProfileVideo reverseIcons={props.reverseIcons} vid={props.vid} />
			<ProfileQA questions={props.questions} />
			<div className="home_button_wrap">
				<p className="cta_lead">
					What will you become at the University of Arizona? <span>Apply or commit, and be an Arizona Wildcat.</span>
				</p>
				<a id={'apply-now-' + props.studentName} href="https://slate.admissions.arizona.edu/apply/">
					APPLY NOW
				</a>
				<a id={'commit-now-' + props.studentName} href="https://nextsteps.arizona.edu/nextsteps/api/trackingLink?CampaignId=fresh-we-are-wildcats-nscl-cta">
					COMMIT NOW
				</a>
			</div>
		</div>
	);
};

export default ProfileBody;
