import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Style from './Menu.css';

const Menu = props => {
	const height = window.innerHeight - 94;
	const lastColor = props.lastColor;

	const [ref, inView] = useInView({
		threshold: 0.1,
		rootMargin: '0px 0px -' + height + 'px 0px'
	});

	useEffect(() => {
		if (inView) {
			props.reverseIcons('white');
			console.log('hi menu');
		}
		if (!inView) {
			props.reverseIcons(lastColor);
		}
	}, [inView]);

	const [xPos, setXPos] = useState(0);
	const [yPos, setYPos] = useState(0);

	const mouseOverTrack = e => {
		setXPos(e.nativeEvent.offsetX);
		setYPos(e.nativeEvent.offsetY);
	};

	var imgPosStyle = {
		top: yPos - 50,
		left: xPos - 50,
		position: 'absolute',
		width: '100px',
		height: 'auto',
		zIndex: '-1'
	};

	return (
		<nav ref={ref} style={Style.Menu} className={`main_menu_container ${props.menuState}`}>
			<ul id="main_menu" role="menu" aria-labelledby="menu_bttn">
				{props.studentData.map(profile => (
					<li role="none" key={profile.id} onMouseMove={mouseOverTrack}>
						<img style={imgPosStyle} src={require(`../../img/home-profile/${profile.imgFile}.jpg`)} alt={profile.firstName} />
						<Link to={profile.proPath} onClick={props.toggleMenuState} id={`menu-${profile.firstName}`} role="menuitem">
							<span className="student_name">{profile.firstName.toUpperCase()}</span>
							<span className="student_details">{profile.descrip.toUpperCase()}</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Menu;
