import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Style from './ProfileVideo.css';

const ProfileVideo = props => {
	const height = window.innerHeight - 94;
	const [ref, inView] = useInView({
		threshold: 0,
		rootMargin: '0px 0px -' + height + 'px 0px'
	});

	useEffect(() => {
		if (inView) {
			props.reverseIcons('blue');
		}
	}, [inView]);
	const loadHide = () => {
		document.getElementById('hide_this').style.opacity = 1;
	};

	const style1 = {
		padding: '56.25% 0 0 0',
		position: 'relative'
	};
	
	const style2 = {
		height: '100%',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%'
	};
	
	const vidStr = props.vid;
	return (
		<section ref={ref} style={Style.ProfileVideo} className="profile_video">
			<div className="wrapper">
				<div className="vid_container">
					<div class="video_responsive_padding" style={style1}>
						<div id="hide_this" class="video_responsive_wrapper" style={style2}>
							<iframe title="We Are Wildcats Video" src={`https://player.vimeo.com/video/${vidStr}&title=false&portrait=false&byline=false`} allowtransparency="true" frameborder="0" scrolling="no"  allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProfileVideo;
