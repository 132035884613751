import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Style from './ProfileHero.css';

const ProfileHero = props => {
	const height = window.innerHeight - 94;

	const [ref, inView] = useInView({
		threshold: [0, 0.1],
		rootMargin: '0px 0px -' + height + 'px 0px'
	});

	useEffect(() => {
		if (inView) {
			props.reverseIcons('white');
			console.log('hi hero');
		}
	}, [inView]);

	const student = props.studentData,
		bgImg = require(`../../img/profile/${student.imgFile}.jpg`);

	return (
		<section ref={ref} style={Style.ProfileHero} className="lowerLev">
			<div className="hero_container" style={{ backgroundImage: 'url(' + bgImg + ')' }}>
				<div className="wrapper">
					<div className="hero_text">
						<h1>{student.firstName.toUpperCase()}</h1>
						<span>{`${student.year.toUpperCase()} | ${student.major.toUpperCase()} | ${student.homeCity.toUpperCase()}, ${student.homeState.toUpperCase()}`}</span>
						<p className="hero_summary">{student.studentSummary}</p>
					</div>
					<div class="mouse_wave">
						<span class="scroll_arrows one"></span>
						<span class="scroll_arrows two"></span>
						<span class="scroll_arrows three"></span>
					</div>
				</div>
				<div aria-hidden="true" className="hidden_name">
					{student.firstName.toUpperCase()}
				</div>
			</div>
		</section>
	);
};

export default ProfileHero;
